<template>
  <v-container
    fill-height>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        class="register-form"
      >
        <v-card>
          <v-card-text>
             <v-form
              ref="registrationForm"
              v-model="valid">
                <v-alert
                    color="primary"
                    dark
                    border="left"
                    prominent
                >
                  Passwords must include at least one uppercase character,
                  one lowercase character, one number, and one special character.
                </v-alert>
                <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.min, rules.emailAddress]"
                    label="Email Address"
                    required
                />
                <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, rules.passwordStrength]"
                    :type="showPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="showPassword = !showPassword"
                />
                <v-text-field
                    v-model="repeatPassword"
                    :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, rules.passwordMatch]"
                    :type="showRepeatPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="Repeat Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="showRepeatPassword = !showRepeatPassword"
                />
                <v-btn
                    :disabled="!valid"
                    @click="register()"
                >
                  Create Account
                </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { STRONG_PASSWORD_REGEX, EMAIL_REGEX } from '../shared/constants/regex';

export default {
  data() {
    return {
      valid: true,
      email: '',
      password: '',
      showPassword: false,
      repeatPassword: '',
      showRepeatPassword: '',
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailAddress: (v) => v.match(EMAIL_REGEX) != null || 'Must be a valid email address.',
        passwordMatch: () => this.password === this.repeatPassword || 'Password must match',
        passwordStrength: () => this.password.match(STRONG_PASSWORD_REGEX) != null || 'Password does not meet complexity requirements',
      },
    };
  },
  methods: {
    register() {
      this.$refs.registrationForm.validate();
      if (!this.valid) return;

      const data = {
        user: {
          email: this.email,
          password: this.password,
          company_id: 1,
        },
      };

      this.$store.dispatch('register', data)
        .then(() => this.$store.commit('showMessage', { content: 'Account Created.', color: 'green' }))
        .catch((err) => {
          console.log('error', err);
          this.$store.commit('showMessage', { content: 'An error occurred while creating account.', color: 'red' });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  .register-form {
    max-width: 500px;
  }
</style>
